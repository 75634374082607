import React, { Children, useEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import * as Yup from "yup";
import { PhoneInterface } from "../../../assets/interfaces";
import { FormikErrors, FormikProps } from "formik";
import countryCodes from "../../../assets/js/utils/countries.json";

export interface PhoneInputProps
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "value" | "onChange"
  > {
  className?: string;
  label: string;
  name: string;
  error?: { code?: string; digits?: string };
  errors?: FormikErrors<any>;
  value?: PhoneInterface;
  onChange?: (e: any) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  label,
  disabled,
  className,
  name,
  children,
  error,
  value,
  onChange,
  ...props
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [code, setCode] = useState(value.code ?? "+234");
  const [digits, setDigits] = useState(value.digits);
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const theError = error?.code || error?.digits || "";

  useClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setShowDropdown(false);
  }, [code]);

  useEffect(() => {
    onChange({ target: { name, value: { code, digits } } });
  }, [code, digits]);

  useEffect(() => {
    if (value.code !== code && value.code !== "") {
      setCode(value.code);
    }
    if (value.digits !== digits && value.digits !== "") {
      setDigits(value.digits);
    }
  }, [value]);

  const isSelected = (thisCode) => thisCode === code;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    if (value.indexOf("0") === 0) {
      value = value.replace("0", "");
    }

    setDigits(value);
  };

  return (
    <div className={`w-full mt-4 first:mt-0 ${className} ${disabled && "opacity-60"}`}>
      <div className={`h-11.5 w-full relative flex items-center font-action`}>
        <button
          className={` ${
            disabled && "cursor-default"
          } w-[80px] bg-opacity-20 hover:bg-opacity-30 transition-all duration-100 flex items-center justify-center text-support text-sm absolute z-[100]`}
          type="button"
          onClick={() => setShowDropdown(true)}
          ref={dropdownRef}
          disabled={disabled}
        >
          <div className="text-dark flex items-center text-sm font-medium">
            <span className="inline-block pt-0.5">{`${code}`}</span>
            <svg viewBox="0 0 14 8" fill="none" className="ml-1.25 w-2.5 transform rotate-180">
              <path
                d="M13 7L7 1L1 7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div
            className={`bg-white w-[220px] left-0 absolute -top-2 rounded-md transform transition-all duration-200 ease-out max-h-[280px] overflow-y-auto shadow-card border border-grey-light z-[999] ${
              showDropdown ? "opacity-100 translate-y-0" : "pointer-events-none opacity-0 translate-y-10"
            }`}
          >
            <ul className="flex flex-col divide-y divide-grey-divider" onClick={(e) => e.stopPropagation()}>
              {countryCodes.map((c, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setCode(c.dial_code);
                    setShowDropdown(false);
                  }}
                  className={`px-3 pt-3 pb-2.5 text-left bg-grey-light transition-all duration-100 ease-out text-dark ${
                    isSelected(c.dial_code) ? "bg-opacity-40" : "bg-opacity-0 hover:bg-opacity-25"
                  }`}
                >
                  <span className="pt-1 inline-block">{c.emoji}</span> {c.name}{" "}
                  <span className="text-dark">{c.dial_code}</span>
                </li>
              ))}
            </ul>
          </div>
        </button>
        <input
          {...props}
          name={name}
          value={digits}
          onChange={handleChange}
          disabled={disabled}
          placeholder=" "
          className={`input-field h-full w-full !outline-none border border-grey-divider bg-grey-fields-100 bg-opacity-40 rounded-lg text-base placeholder-shown:text-1sm text-black-secondary px-4 sm:px-5 !pl-[80px] `}
        />
        <label
          htmlFor={name}
          className="text-sm text-placeholder absolute cursor-text pointer-events-none inline-block pl-[60px]"
        >
          {label}
        </label>
        {children}
      </div>
      {theError && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path
              d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block pt-1">{theError}</span>
        </div>
      )}
    </div>
  );
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required("Country code is required"),
  digits: Yup.string()
    .required("Phone number is required")
    .test("minlen", "Phone number must be at least 7 digits", (val) => val.length >= 7)
    .test("maxlen", "Phone number must be at most 15 digits", (val) => val.length <= 15)
    .test("digits", "Phone number should contain only digits", (value) => /^\d+$/.test(value)),
});

export default PhoneInput;
