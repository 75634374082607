import classNames from "classnames";
import { FormikProps } from "formik";
import React, { Children, ReactElement, useState } from "react";

export interface InputFieldProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  label: string;
  name: string;
  error?: string;
  prefixIcon?: ReactElement;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  disabled,
  className,
  name,
  children,
  error,
  prefixIcon,
  ...props
}) => {
  return (
    <div className={`w-full mt-4 first:mt-0 ${className} ${disabled && "opacity-60"}`}>
      <div className={`h-12.5 w-full relative flex items-center font-action`}>
        <input
          {...props}
          name={name}
          // id={name}
          disabled={disabled}
          placeholder=" "
          className={classNames(
            `input-field h-full w-full !outline-none border border-grey-divider bg-grey-fields-100 bg-opacity-40 rounded-lg text-base placeholder-shown:text-1sm text-black-secondary px-4 sm:px-5`,
            { "!pl-[34px]": prefixIcon }
          )}
        />
        {prefixIcon && <div className="pl-2.5 absolute">{prefixIcon}</div>}
        <label
          htmlFor={name}
          className={classNames(`text-sm text-placeholder absolute cursor-text pointer-events-none`, {
            "has-prefix ml-[34px]": prefixIcon,
          })}
        >
          {/* {prefixIcon && <div className="pr-1.5">{prefixIcon}</div>} */}
          {label}
        </label>
        {children}
      </div>
      {error && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path
              d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block">{error}</span>
        </div>
      )}
    </div>
  );
};

interface InputWithAdddonProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  className?: string;
  name: string;
  error?: string;
}

export const InputWithAddon: React.FC<InputWithAdddonProps> = ({
  label,
  className,
  name,
  children,
  error,
  value: initialValue,
  onBlur,
  onChange,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    setValue(target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="mt-3.5 first:mt-0 w-full">
      {label && (
        <label className="text-grey-subtext text-sm relative left-3 bg-white p-2 rounded-full top-3 z-50 mb-1.25">
          {label}
        </label>
      )}
      <div
        className={`h-12.5 w-full relative flex items-center font-action border rounded-lg divide-x divide-grey-divider transition-all duration-200 ease-in-out bg-grey-fields-100 bg-opacity-40 ${
          isFocused ? "border-primary-500" : value ? "border-grey-divider" : "border-grey-divider"
        } ${className}`}
      >
        {children && <div className="h-full overflow-hidden">{children}</div>}
        <input
          {...props}
          name={name}
          id={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
          className={`flex-1 h-full w-full !outline-none placeholder-placeholder focus:placeholder-opacity-80 transition-all duration-200 ease-in-out text-base placeholder-shown:text-1sm text-black-secondary ${
            value ? "bg-white" : "bg-grey-fields-100 bg-opacity-40"
          } ${children ? "rounded-l-none rounded-r-lg px-4 sm:px-5 " : "rounded-lg px-3 sm:px-3.5"} `}
        />
      </div>
      {error && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path
              d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block pt-1">{error}</span>
        </div>
      )}
    </div>
  );
};

export default InputField;
